
.center{
    margin: auto;
    width: 70%;
}

.about{
    display: block;
    float: left;
    margin-left: 25%;
    margin-right: 5%;
    width: 20%;
}

.image{
    display: block;
    width: 30%;
}

@media screen and (max-width: 1150px) {
    .center{
        width: 70%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .about{
        margin-left: 15%;
        margin-right: auto;
        width: 70%;
    }

    .image{
        margin: auto;
        width: 60%
    }
}

@media screen and (max-width: 750px) {
    .center{
        margin: auto;
        width: 100%;
    }

    .image{
        width: 45%
    }
}

@media screen and (max-width: 575px) {
    .image{
        width: 60%
    }
}