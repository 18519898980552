.name{
    padding-top: 24%;
    padding-bottom: 24%;
    color: white;
    text-align: center;
    background-image: url('./background/bg(lg).JPG');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    font-size: 90px;
}

.intro{
    color: black;
    text-align: center;
}

@media screen and (max-width: 1410px) {
    .name{
        background-image: url('./background/bg(md).JPG');
    }
}

@media screen and (max-width: 992px) {
    .name{
        background-image: url('./background/bg(sm).JPG');
    }
}


@media screen and (max-width: 708px) {
    .name{
        padding-top: 20%;
        font-size: 70px;
        background-image: url('./background/bg(xs).JPG');
    }
}

@media screen and (max-width: 420px) {
    .name{
        background-image: url('./background/bg(xxs).JPG');
    }
}