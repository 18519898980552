@media screen and (max-width: 575px) {
    .centerMobile{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media screen and (min-width: 575px) {
    .centerOther{
      align-items: center;
      justify-content: center;
    }
  }