.footer{
    margin-top: 5%;
    background: #333a40;
    padding: 5%;
}

.text{
    color: white;
    text-align: center;
    margin-bottom: 15px;
}

.git{
    background-image: url('./icons/git.png');
    height:45px;
    width:45px;
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 15px;
}

.in{
    background-image: url('./icons/in.png');
    height:45px;
    width:45px;
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
}